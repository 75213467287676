import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Response} from '../../../shared/models/response';
import { CustomAttributes } from '../../../shared/models/custom-attributes';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import { SkilljarEvent } from 'src/app/shared/models/skilljar-event.model';

@Injectable({
  providedIn: "root",
})
export class ConnectorService {
  constructor(private http: HttpClient) {}

  getCustomFieldsByCommunityId(sourceCommunityId, entityType?: any): Observable<any[]> {
    let url =
      `${environment.BASE_URL}/community/customfields/` + sourceCommunityId;
    if (entityType) {
        url = url + "?entityType=" + entityType;
    }
    return this.http.get<any[]>(url);
  }

  saveFieldMappings(fieldMappings): Observable<Response<any>> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const payLoad = fieldMappings;
    const url = `${environment.BASE_URL}/savefieldmappings`;
    return this.http.post<Response<any>>(url, payLoad, {
      headers,
    });
  }

  getConnectorMappings(
    customerId: any,
    pageSize: any,
    currentPage: any,
    sortName: any,
    sortOrder: any,
    searchString: string
  ): Observable<any[]> {
    const url = `${environment.BASE_URL}/connectormappings/${customerId}?pageIndex=${currentPage}&pageLimit=${pageSize}&sortOrder=${sortOrder}&sortColumn=${sortName}&filter=${searchString}`;
    return this.http.get<any[]>(url);
  }

  getFieldMappings(connectorId: any) {
    const url = `${environment.BASE_URL}/getfieldmappings/` + connectorId;
    return this.http.get<any[]>(url);
  }

  deleteConnectorMapping(connectorId: any) {
    const url = `${environment.BASE_URL}/deleteconnectormapping/` + connectorId;
    return this.http.get<Response<any>>(url);
  }

  getSchedules(connectorId: any) {
    const url = `${environment.BASE_URL}/community/schedules/` + connectorId;
    return this.http.get<any[]>(url);
  }
  getUnprocessedSkilljarEvents(connectorId: any){
    const url=`${environment.BASE_URL}/community/getskilljarunprocessedevents/` + connectorId;
    return this.http.get<any[]>(url);
  }

  getSchedulesByType(type: any, connectorId: any) {
    const url =
      `${environment.BASE_URL}/community/schedules/` + type + "/" + connectorId;
    return this.http.get<any[]>(url);
  }

  configSchedule(data: any): Observable<any> {
    const headers = new HttpHeaders().set("content-type", "application/json");
    const payLoad = data;
    const url = `${environment.BASE_URL}/community/scheduleConfig`;
    return this.http.post<any>(url, payLoad, {
      headers,
    });
  }

  configReprocess(connectorId: any) {
    const url =
      `${environment.BASE_URL}/community/schedules/reprocess` +
      "/" +
      connectorId;
    return this.http.get<Response<any>>(url);
  }
  resetUsers(connectorId: any) {
    // const headers = new HttpHeaders().set('content-type', 'application/json');
    // const payLoad = data;
    const url =
      `${environment.BASE_URL}/community/schedules/userreset` +
      "/" +
      connectorId;
    return this.http.get<Response<any>>(url);
  }

  retryFailedSchedules(rebuildId: any) {
    const url =
      `${environment.BASE_URL}/community/schedules/retry` + "/" + rebuildId;
    return this.http.post<any>(url, null);
  }
  reprocessSkillJarEvents(payload: SkilljarEvent){
    const url=`${environment.BASE_URL}/community/schedules/reprocesseventsofskilljar`;
    return this.http.post<any>(url,payload);

  }
  submitFile = (
    payLoad: any,
    source_community_id: any,
    target_community_id: any
  ) => {
    const url =
      `${environment.BASE_URL}/uploadcsvfile?source-community-id=` +
      source_community_id +
      `&target-community-id=` +
      target_community_id;
    return this.http.post<any>(url, payLoad);
  };

  downloadCSV() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: "text",
      }),
    };

    const url = `${environment.BASE_URL}/downloadmigrationmaster`;
    console.log("URL ", url);
    return this.http.get<any>(url, httpOptions);
  }

  processMissingIds = (payLoad: any) => {
    const url = `${environment.BASE_URL}/community/processmissingids`;
    return this.http.post<any>(url, payLoad);
  };
}
