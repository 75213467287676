import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnvironmentType } from '../../../shared/models/environment_type';
import { CommunityService } from '../../../core/services/community/community.service';
import { Constants } from '../../../shared/constants/constants';
import { Community } from '../../../shared/models/community';
import { InteractionStylesComponent } from '../interaction-styles/interaction-styles.component';
// @ts-ignore
import Jsonfile from '../IgnoredFormfields/Ignored.community.form.fields.json';
import {
  MatSnackBar, MatSnackBarConfig
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-access-details',
  templateUrl: './access-details.component.html',
  styleUrls: ['./access-details.component.css']
})
export class AccessDetailsComponent implements OnInit {

  @Output() showMessage = new EventEmitter();
  @Output() emitData = new EventEmitter();

  @ViewChild(InteractionStylesComponent, { static: true }) interactionSTyleComponent;

  accessDetailsForm: FormGroup;
  showSpinner: boolean;
  isHtAccessEnabled: boolean;
  isStorageType: boolean;
  azureAuthType: string;
  environmentTypes: EnvironmentType[] = [];
  communityTypeName: string;
  isUpdate: boolean;
  config = new MatSnackBarConfig();

  constructor(private _fb: FormBuilder,
    private communityService: CommunityService, private snackBar: MatSnackBar) {
    this.buildForm();
  }

  ngOnInit() {
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'end';
    this.config.duration = 5000;
    this.config.panelClass = ['blue-snackbar'];
    this.fetchEnvironments();
  }


  buildForm() {
    this.accessDetailsForm = this._fb.group({
      autthenicationToken: new FormControl('', [Validators.required]),
      verifyToken: new FormControl('', [Validators.required]),
      // Added tenant name and Access Token Url for SharePoint community type
      tenantName: new FormControl('', [Validators.required]),
      accessTokenURL: new FormControl('', [Validators.required]),
      storageType: new FormControl('',[]),
      environment: new FormControl('', [Validators.required]),
      htAccess: new FormControl('', []),
      clientID: new FormControl('', [Validators.required]),
      clientSecret: new FormControl('', [Validators.required]),
      accessToken: new FormControl('', [Validators.required]),
      refreshToken: new FormControl('', [Validators.required]),
      bulkDataCommunityId: new FormControl('', []),
      bulkDataClientId: new FormControl('', []),
      resourceGroup: new FormControl('', []),
      subscriptionId: new FormControl('', []),
      bulkAPIAccessToken: new FormControl('', []),
      azureStorageConnectionUrl: new FormControl('', []),
      azureauthtype: new FormControl('',[]),
      azureFileShare: new FormControl('',[]),
      azureFolder: new FormControl('',[]),
      coveoOrganizationId: new FormControl('', []),
      coveoDatasourceId: new FormControl('', []),
      coveoAPIKey: new FormControl('', []),
      coveoFetchAPIKey: new FormControl('', []),
      securityIdentityProviderName: new FormControl('', []),
      awsAPIKey: new FormControl('', []),
      chamaAPIKey: new FormControl('', []),
      chamaMACKey: new FormControl('', []),
      smartsheetAPIKey: new FormControl('', []),
      smartlingSignature: new FormControl('', []),
      communityUserAuthenticationToken:  new FormControl('', []),
      zendeskDatabaseUserName:  new FormControl('', [Validators.required]),
      zendeskDatabasePassword:  new FormControl('', [Validators.required]),
      zendeskDatabaseServer:  new FormControl('', [Validators.required]),
      zendeskDatabaseName:  new FormControl('', [Validators.required])
    });
  }

  fetchEnvironments() {
    this.showSpinner = true;
    this.communityService.getEnvironments()
      .subscribe(res => {
        this.showSpinner = false;
        this.environmentTypes = res.response.data;
      },
        error => {
          this.showSpinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
          } else {
            return this.snackBar.open(error.error.response.message, 'Close', this.config);
          }
        });
  }

  checkHtAccess(event) {
    const selectedValue = event.target.value;
    if (selectedValue !== '') {
      this.isHtAccessEnabled = selectedValue !== '1' ? true : false;
    } else {
      this.isHtAccessEnabled = false;
    }
    this.emitData.emit({
      'environemnts': this.environmentTypes
    });
  }
  checkAuthenticationType(event) { 
    const selectedValue = event.target.value;
    this.azureAuthType = selectedValue;
    if (selectedValue !== '') {
      this.isStorageType = true;
    } else {
      this.isStorageType = false;
    }
    this.accessDetailsForm.controls['resourceGroup'].setValue("");
    this.accessDetailsForm.controls['subscriptionId'].setValue("");
  }


  reset() {
    this.accessDetailsForm.reset();
  }

  private setDataToForm(community: Community) {
    this.accessDetailsForm.controls['autthenicationToken'].setValue(community.accessdetail.authenticationToken);
    this.isHtAccessEnabled = community.environmentType.environmentType === 'STAGE' ? true : false;
    this.accessDetailsForm.controls['environment'].setValue(community.environmentType.environmentTypeId);
    this.accessDetailsForm.controls['htAccess'].setValue(community.accessdetail.htAccess);
    this.accessDetailsForm.controls['clientID'].setValue(community.accessdetail.clientId);
    this.accessDetailsForm.controls['clientSecret'].setValue(community.accessdetail.clientSecret);
    this.accessDetailsForm.controls['accessToken'].setValue(community.accessdetail.accessToken);
    this.accessDetailsForm.controls['refreshToken'].setValue(community.accessdetail.refreshToken);
    this.accessDetailsForm.controls['smartlingSignature'].setValue(community.accessdetail.smartlingSignature);
    this.accessDetailsForm.controls['communityUserAuthenticationToken'].setValue(community.accessdetail.communityUserAuthenticationToken);
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_KHOROS || community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_KHOROS_CLASSIC) {
      this.accessDetailsForm.controls['bulkDataCommunityId'].setValue(community.accessdetail.bulkDataCommunityId);
      this.accessDetailsForm.controls['bulkDataClientId'].setValue(community.accessdetail.bulkDataClientId);
      this.accessDetailsForm.controls['bulkAPIAccessToken'].setValue(community.accessdetail.bulkAPIAccessToken);
      if (community.accessdetail.azureAuthenticationType == "ManagedIdentity") {
        this.accessDetailsForm.controls['azureStorageConnectionUrl'].setValue(community.accessdetail.azureStorageAccountName);
      } else { 
        this.accessDetailsForm.controls['azureStorageConnectionUrl'].setValue(community.accessdetail.azureStorageConnectionUrl);
      }
      this.accessDetailsForm.controls['azureFileShare'].setValue(community.accessdetail.azureFileShare);
      this.accessDetailsForm.controls['azureFolder'].setValue(community.accessdetail.azureFolder);
      this.accessDetailsForm.controls['azureauthtype'].setValue(community.accessdetail.azureAuthenticationType);
      if (community.accessdetail.azureAuthenticationType != "" && community.accessdetail.azureAuthenticationType != undefined) { 
        this.isStorageType=true
      }
      this.azureAuthType = community.accessdetail.azureAuthenticationType;
      this.accessDetailsForm.controls['resourceGroup'].setValue(community.accessdetail.azureResourceGroup)
      this.accessDetailsForm.controls['subscriptionId'].setValue(community.accessdetail.azureSubscriptionId)
      this.accessDetailsForm.controls['storageType'].setValue(community.accessdetail.azureStorageType)
    } else if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_TELLIGENT || community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_FACEBOOK) {
      this.accessDetailsForm.controls['verifyToken'].setValue(community.accessdetail.verifyToken);
    }
    // If community type is share point then sets the tenant name and access token url to community access details
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_SHARE_POINT) {
      this.accessDetailsForm.controls['tenantName'].setValue(community.accessdetail.tenantName);
      this.accessDetailsForm.controls['accessTokenURL'].setValue(community.accessdetail.accessTokenURL);
    }

    // If community type is Azure-Storage
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_AZURE_STORAGE) {
      if (community.accessdetail.azureAuthenticationType == "ManagedIdentity") {
        this.accessDetailsForm.controls['azureStorageConnectionUrl'].setValue(community.accessdetail.azureStorageAccountName);
      } else { 
        this.accessDetailsForm.controls['azureStorageConnectionUrl'].setValue(community.accessdetail.azureStorageConnectionUrl);
      }
      this.accessDetailsForm.controls['azureFileShare'].setValue(community.accessdetail.azureFileShare);
      this.accessDetailsForm.controls['azureFolder'].setValue(community.accessdetail.azureFolder);
      this.accessDetailsForm.controls['azureauthtype'].setValue(community.accessdetail.azureAuthenticationType);
      this.accessDetailsForm.controls['storageType'].setValue(community.accessdetail.azureStorageType)
      if (community.accessdetail.azureAuthenticationType != "" && community.accessdetail.azureAuthenticationType != undefined) { 
        this.isStorageType=true
      }
      this.azureAuthType = community.accessdetail.azureAuthenticationType;
      this.accessDetailsForm.controls['resourceGroup'].setValue(community.accessdetail.azureResourceGroup)
      this.accessDetailsForm.controls['subscriptionId'].setValue(community.accessdetail.azureSubscriptionId)
      this.accessDetailsForm.controls['storageType'].setValue(community.accessdetail.azureStorageType)
    }
    // If community type is Coveo
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_COVEO) {
      this.accessDetailsForm.controls['coveoOrganizationId'].setValue(community.accessdetail.coveoOrganizationId);
      this.accessDetailsForm.controls['coveoDatasourceId'].setValue(community.accessdetail.coveoDatasourceId);
      this.accessDetailsForm.controls['coveoAPIKey'].setValue(community.accessdetail.coveoAPIKey);
      this.accessDetailsForm.controls['coveoFetchAPIKey'].setValue(community.accessdetail.coveoFetchAPIKey);
      this.accessDetailsForm.controls['securityIdentityProviderName'].setValue(community.accessdetail.securityIdentityProviderName);
    }

    // If community type is Chama
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_WEB_PROXY) {
      this.accessDetailsForm.controls['awsAPIKey'].setValue(community.accessdetail.awsAPIKey);
    }

    // If community type is Chama
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_CHAMA) {
      this.accessDetailsForm.controls['chamaAPIKey'].setValue(community.accessdetail.chamaAPIKey);
    }

    // If community type is Chama
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_CHAMA) {
      this.accessDetailsForm.controls['chamaMACKey'].setValue(community.accessdetail.chamaMACKey);
    }

    // If community type is SmartSheet
     if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_SMARTSHEET) {
      this.accessDetailsForm.controls['smartsheetAPIKey'].setValue(community.accessdetail.smartsheetAPIKey);
    }

    // If community type is Zendesk DataBase Service
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_ZENDESK_DB_SERVICE) {
      this.accessDetailsForm.controls['zendeskDatabaseUserName'].setValue(community.accessdetail.zendeskDatabaseUserName);
      this.accessDetailsForm.controls['zendeskDatabasePassword'].setValue(community.accessdetail.zendeskDatabasePassword);
      this.accessDetailsForm.controls['zendeskDatabaseServer'].setValue(community.accessdetail.zendeskDatabaseServer);
      this.accessDetailsForm.controls['zendeskDatabaseName'].setValue(community.accessdetail.zendeskDatabaseName);
    }
    // If community type is sharepoint storage service
    if (community.communityType.communityTypeName === Constants.COMMUNITY_TYPE_SHAREPOINT_STORAGE_SERVICE) {
      this.accessDetailsForm.controls['tenantName'].setValue(community.accessdetail.tenantName);
    }
  }

  public getJsonObj(communityType: string) {
    this.communityTypeName = communityType;
    this.buildForm();
  }

  public canShow(field: string): boolean {
    if (this.communityTypeName !== undefined) {
      const type = this.communityTypeName;  // === Constants.KHOROS ? Constants.COMMUNITY_TYPE_LITHIUM : this.communityTypeName;
      const data = Jsonfile[type];
      
      if (data && data[field] === undefined || data[field] === true) {
        return true;
      }
      this.accessDetailsForm.removeControl(field);
      return false;
    }
  }


  edit(community: Community) {
    this.buildForm();
    this.setDataToForm(community);
    this.isUpdate = true;
  }

  enableFormFields() {
    this.isUpdate = false;
    this.reset();
    this.accessDetailsForm.controls['environment'].setValue('');
  }
}
