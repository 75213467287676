import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunityService } from '../../../core/services/community/community.service';
import { Community } from '../../../shared/models/community';
// @ts-ignore
import Jsonfile from '../IgnoredFormfields/Ignored.community.form.fields.json';

@Component({
  selector: 'app-community-info',
  templateUrl: './community-info.component.html',
  styleUrls: ['./community-info.component.css']
})
export class CommunityInfoComponent implements OnInit, AfterViewInit{
  @ViewChild("mTLSCertificateLabel") mTLSCertificateLabel: ElementRef;
  @ViewChild("mTLSKeyLabel") mTLSKeyLabel: ElementRef;
  @ViewChild("gcpKeyLabel") gcpKeyLabel: ElementRef;
  @ViewChild("mTLSKey", { static: true })
  @Output() emitIcstenantId = new EventEmitter();
  @Input() isValidated: boolean;

  communityInfoForm: FormGroup;
  showSpinner: boolean;
  communityTypeName: string;
  isUpdate = false;
  gcpKeyFileUpload: File = null;
  mTLSKeyFileUpload: File = null;
  mTLSKeyCertificateUpload: File = null;
  constructor(private _fb: FormBuilder,
    private communityService: CommunityService) {
    this.buildForm();
  }
// MtlsFILE_EXTENSION_SUPPORTED
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.gcpKeyLabel?this.gcpKeyLabel.nativeElement.focus():null;
    this.mTLSKeyLabel?this.mTLSKeyLabel.nativeElement.focus():null;
    this.mTLSCertificateLabel?this.mTLSCertificateLabel.nativeElement.focus():null;
    }
  buildForm() {
    this.communityInfoForm = this._fb.group({
      communityName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9]([\\w -]*[a-zA-Z0-9])?$')]),
      tenantID: new FormControl('', [Validators.required]),
      communityActAS: new FormControl('BOTH'),
      communityURL: new FormControl('', [Validators.required,
      Validators.pattern('https?:\\/\\/?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,3}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]),
      apiHostURL: new FormControl('', [Validators.required,
      Validators.pattern('https?:\\/\\/?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,3}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]),
      registrationURL: new FormControl('', [Validators.required,
      Validators.pattern('https?:\\/\\/?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,3}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')]),
      validateCommunityBy: new FormControl('', [Validators.required]),
      linkedInpageId: new FormControl('', [Validators.required]),
      smartsheetFolderId: new FormControl(''),
      templateSheetId: new FormControl(''),
      chamaEndpointUrl: new FormControl(''),
      awsEndpoint: new FormControl(''),
      awsS3Url: new FormControl(''),
      hiddenBoardIds: new FormControl(''),
      nodeType: new FormControl(''),
      nodeId: new FormControl(''),
      syndicateTags: new FormControl(''),
      syndicateLabels: new FormControl(''),
      icsTenantId: new FormControl(''),
      language: new FormControl(''),
      authtype: new FormControl(''),
      coveoExtensionId: new FormControl(''),
      excludedRoles: new FormControl(''),
      includedRoles: new FormControl(''),
      khorosPullMechanismEnabled: new FormControl(''),
      zendeskScope: new FormControl(''),
      resourceType: new FormControl(''),
      grantType: new FormControl(''),
      tenantName: new FormControl(''),
      siteName: new FormControl(''),
      subredditName: new FormControl(''),
      projectId: new FormControl(''),
      bucketName: new FormControl(''),
      datastoreURL: new FormControl(''),
      datastoreId: new FormControl(''),
      authenticationType: new FormControl(''),
      higherlogicCommunityName: new FormControl(''),
      sharepointStorageId: new FormControl(''),
      postAsMachineUser: new FormControl(''),
      canonicalUrlEnabled: new FormControl(''),
      targetMessageUpdatesSyndication: new FormControl('true'),
      targetThreadRepliesSyndication: new FormControl('true'),
      khorosSleUpdate:new FormControl(''),
      messageHiddenTagSyndication: new FormControl(''),
      hierarchicalLabels:new FormControl(''),
      fieldMappingEnabled: new FormControl('true'),
      mTLSKeyCertificateUpload: new FormControl(null),
      mTLSKeyFileUpload: new FormControl(null),
      gcpKeyFileUpload: new FormControl(null),
      account: new FormControl(''),
      profile: new FormControl(''),
      datasource: new FormControl(''),
      dataSet: new FormControl(''),
      tableName: new FormControl('')
    });
  }

  private setDataToForm(community: Community) {
    this.communityInfoForm.controls['communityName'].setValue(community.communityName);
    this.communityInfoForm.controls['tenantID'].setValue(community.tenantId);
    this.communityInfoForm.controls['zendeskScope'].setValue(community.zendeskScope);
    this.communityInfoForm.controls['communityActAS'].setValue(community.communityFunction);
    this.communityInfoForm.controls['communityURL'].setValue(community.communityUrl);
    this.communityInfoForm.controls['apiHostURL'].setValue(community.apiUrl);
    this.communityInfoForm.controls['registrationURL'].setValue(community.registrationUrl);
    this.communityInfoForm.controls['validateCommunityBy'].setValue(community.userValidateBy);
    this.communityInfoForm.controls['syndicateTags'].setValue(community.syndicateTags);
    this.communityInfoForm.controls['syndicateLabels'].setValue(community.syndicateLabels);
    this.communityInfoForm.controls['icsTenantId'].setValue(community.icsTenantId);
    this.communityInfoForm.controls['linkedInpageId'].setValue(community.linkedInpageId);
    this.communityInfoForm.controls['smartsheetFolderId'].setValue(community.smartsheetFolderId);
    this.communityInfoForm.controls['templateSheetId'].setValue(community.templateSheetId);
    this.communityInfoForm.controls['chamaEndpointUrl'].setValue(community.chamaEndpointUrl);
    this.communityInfoForm.controls['awsEndpoint'].setValue(community.awsEndpoint);
    this.communityInfoForm.controls['awsS3Url'].setValue(community.awsS3Url);
    this.communityInfoForm.controls['hiddenBoardIds'].setValue(community.hiddenBoardIds);
    this.communityInfoForm.controls['coveoExtensionId'].setValue(community.coveoExtensionId);
    this.communityInfoForm.controls['resourceType'].setValue(community.resourceType);
    this.communityInfoForm.controls['grantType'].setValue(community.grantType);
    this.communityInfoForm.controls['tenantName'].setValue(community.tenantName);
    this.communityInfoForm.controls['siteName'].setValue(community.siteName);
    this.communityInfoForm.controls['subredditName'].setValue(community.subredditName);
    this.communityInfoForm.controls['projectId'].setValue(community.projectId);
    this.communityInfoForm.controls['bucketName'].setValue(community.bucketName);
    this.communityInfoForm.controls['datastoreURL'].setValue(community.datastoreURL);
    this.communityInfoForm.controls['datastoreId'].setValue(community.datastoreId);
    this.communityInfoForm.controls['authenticationType'].setValue(community.authenticationType);
    this.communityInfoForm.controls['mTLSKeyCertificateUpload'].setValue(community.mTLSKeyCertificateUpload);
    this.communityInfoForm.controls['mTLSKeyFileUpload'].setValue(community.mTLSKeyFileUpload);
    this.communityInfoForm.controls['gcpKeyFileUpload'].setValue(community.gcpKeyFileUpload);
    this.communityInfoForm.controls['higherlogicCommunityName'].setValue(community.higherlogicCommunityName);
    this.communityInfoForm.controls['sharepointStorageId'].setValue(community.sharepointStorageId);
    this.communityInfoForm.controls['khorosPullMechanismEnabled'].setValue(community.khorosPullMechanismEnabled);
    this.communityInfoForm.controls['excludedRoles'].setValue(community.excludedRoles);
    this.communityInfoForm.controls['includedRoles'].setValue(community.includedRoles);
    if (community.hasOwnProperty('bigQueryConfiguration')) {
      if (community.bigQueryConfiguration && community.bigQueryConfiguration.dataSet) {
        this.communityInfoForm.controls['dataSet'].setValue(community.bigQueryConfiguration.dataSet);
      }
      if (community.bigQueryConfiguration && community.bigQueryConfiguration.tableName) {
        this.communityInfoForm.controls['tableName'].setValue(community.bigQueryConfiguration.tableName);
      }
      if (community.bigQueryConfiguration && community.bigQueryConfiguration.projectId) {
        this.communityInfoForm.controls['projectId'].setValue(community.bigQueryConfiguration.projectId);
      }
      if (community.includedRoles && community.bigQueryConfiguration.includedRoles) {
        this.communityInfoForm.controls['includedRoles'].setValue(community.bigQueryConfiguration.includedRoles);
      }
    }
    if (community.hasOwnProperty('tealium')) {
      if (community.tealium && community.tealium.account) {
        this.communityInfoForm.controls['account'].setValue(community.tealium.account);
      }
      if (community.tealium && community.tealium.profile) {
        this.communityInfoForm.controls['profile'].setValue(community.tealium.profile);
      }
      if (community.tealium && community.tealium.datasource) {
        this.communityInfoForm.controls['datasource'].setValue(community.tealium.datasource);
      }
    }
    if (community.hasOwnProperty('nodeId') &&
      (community.nodeId !== undefined || community.nodeId !== null || community.nodeId !== '')) {
      this.communityInfoForm.controls['nodeId'].setValue(community.nodeId);
    } else {
      this.communityInfoForm.controls['nodeId'].setValue('');
    }
    if (community.hasOwnProperty('nodeType') &&
      (community.nodeType !== undefined || community.nodeType !== null || community.nodeType !== '')) {
      this.communityInfoForm.controls['nodeType'].setValue(community.nodeType);
    } else {
      this.communityInfoForm.controls['nodeType'].setValue('');
    }
    this.communityInfoForm.controls['postAsMachineUser'].setValue(community.postAsMachineUser);
    this.communityInfoForm.controls['canonicalUrlEnabled'].setValue(community.canonicalUrlEnabled);
    this.communityInfoForm.controls['targetMessageUpdatesSyndication'].setValue(community.targetMessageUpdatesSyndication);
    this.communityInfoForm.controls['targetThreadRepliesSyndication'].setValue(community.targetThreadRepliesSyndication);
    this.communityInfoForm.controls['khorosSleUpdate'].setValue(community.khorosSleUpdate);
    this.communityInfoForm.controls['messageHiddenTagSyndication'].setValue(community.messageHiddenTagSyndication);
    this.communityInfoForm.controls['hierarchicalLabels'].setValue(community.hierarchicalLabels);
    this.communityInfoForm.controls['fieldMappingEnabled'].setValue(community.fieldMappingEnabled);

  }

  receiveCommunityTypeName(communityTypeName: string) {
    this.communityTypeName = communityTypeName;
    this.buildForm();
  }

  public canShow(field: string): boolean {
    if (this.communityTypeName !== undefined) {
      const type = this.communityTypeName;  //=== Constants.KHOROS ? Constants.COMMUNITY_TYPE_LITHIUM : this.communityTypeName;
      const data = Jsonfile[type];
      if (data && data[field] === undefined || data[field] === true) {
        return true;
      }
      this.communityInfoForm.removeControl(field);
      return false;
    }
  }

  test(event) {
    console.log("first")
  }

  edit(community: Community) {
    this.buildForm();
    this.setDataToForm(community);
    this.isUpdate = true;
  }
  mTLSCertificateChange(files: FileList) {
    console.log("files", files);
    this.mTLSKeyCertificateUpload = files.item(0);
    if (files.length == 0) {
      this.mTLSCertificateLabel.nativeElement.innerHTML = '<i class="fa fa-upload"></i>&nbsp; &nbsp; &nbsp; Choose file';
    } else {
      this.mTLSCertificateLabel.nativeElement.innerText = files[0].name;
     }
  }
  mtlsKeyFileChange(files: FileList) {
    console.log("files", files);
    this.mTLSKeyFileUpload = files.item(0);
    if (files.length == 0) {
          this.mTLSKeyLabel.nativeElement.innerHTML = '<i class="fa fa-upload"></i>&nbsp; &nbsp; &nbsp; Choose file';
    } else {
      this.mTLSKeyLabel.nativeElement.innerText = files[0].name;
     }
    console.log(this.mTLSKeyFileUpload)
  }

  gcpKeyFileChange(files: FileList) {
    console.log("files", files);
    this.gcpKeyFileUpload = files.item(0);
    if (files.length == 0) {
      this.gcpKeyLabel.nativeElement.innerHTML = '<i class="fa fa-upload"></i>&nbsp; &nbsp; &nbsp; Choose file';
    } else {
      this.gcpKeyLabel.nativeElement.innerText = files[0].name;
     }
  }

  enableFormFields() {
    this.communityInfoForm.reset();
    if (this.communityInfoForm.contains('validateCommunityBy')) {
      this.communityInfoForm.controls['validateCommunityBy'].setValue('');
    }
    this.communityInfoForm.controls['communityActAS'].setValue('BOTH');
    this.isUpdate = false;
  }
  icsTenadntIdRegeneration() {
    const icstenantid = this.communityInfoForm.controls['icsTenantId'].value;
    this.emitIcstenantId.emit(icstenantid);
  }
}


