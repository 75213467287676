import {Component, OnInit, ViewChild} from '@angular/core';
import {Customer} from '../../../shared/models/customer';
import {Community} from '../../../shared/models/community';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientsService} from '../../../core/services/customer/clients.service';
import {ModalComponent} from '../../../shared/components/modal/pages/modal.component';
import {Constants} from '../../../shared/constants/constants';
import {CommunityService} from '../../../core/services/community/community.service';
import {MappingsService} from '../../../core/services/Mappings/mappings.service';
import {CommunityLanguage} from '../../../shared/models/community-language';
// @ts-ignore
import Jsonfile from '../IgnoredFormfields/ignored.translation.fields.json';
import {
  MatSnackBar, MatSnackBarConfig
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent implements OnInit {

  @ViewChild(ModalComponent, {static: true}) modal;
  @ViewChild(ModalComponent, { static: true }) modalComponent;
  eventEnabled: boolean = false;
  spinner = false;
  translationForm: FormGroup;
  customers: Customer[] = [];
  communities: Community[] = [];
  languages: CommunityLanguage[] = [];
  translationProviders: string[] = [];
  interationTypes: string[] = [];
  translationAuthTypes: string[] = [];
  smartlingProjects: any[] = [];
  authtype: '';
  customerObj = JSON.parse(localStorage.getItem('customerObj'));
  superAdmin = localStorage.getItem('isSuperAdmin') === 'yes' ? true : false;
  provider = '';
  community: Community;
  config = new MatSnackBarConfig();
  mappedlanguages: any;
  isValidationSuccess: boolean = true;
  showSaveButton: boolean = true;
  smartlingDataDisable:boolean=false;
  isDelete = false;
  smartlingId:any;
  index:any;
  messageToDisplay: any;
  communityName: string;
  customerName: string;

  constructor(private formBuilder: FormBuilder,
              private customerService: ClientsService,
              private communityService: CommunityService,
              private mappingService: MappingsService, private snackBar: MatSnackBar) {
    this.buildForm();
  }

  ngOnInit() {
    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'end';
    this.config.duration = 5000;
    this.config.panelClass = ['blue-snackbar'];
    if (this.superAdmin) {
      this.fetchCustomers();
    } else {
      this.fetchCommunities(this.customerObj.id);
    }
  }


  buildForm() {
    this.translationForm = this.formBuilder.group({
      customer: new FormControl(''),
      community: new FormControl('', [Validators.required]),
      translation: new FormControl(false),
      autoDetectLanguageForLingotek: new FormControl(false),
      translationProvider: new FormControl(''),
      translationLanguageCode: new FormControl(''),
      googleTranslationAuthType: new FormControl(''),
      translateAccessToken: new FormControl(''),
      translateRefreshToken: new FormControl(''),
      translateClientId: new FormControl(''),
      translateClientSecret: new FormControl(''),
      glossariesId: new FormControl(''),
      glossariesLocation : new FormControl(''),
      googleProjectId: new FormControl(''),
      lingotekTranslateAccesskey: new FormControl(''),
      microsoftTranslateSubscriptionKey: new FormControl(''),
      lingotekCallbackUserName: new FormControl(''),
      lingotekCallbackPassword: new FormControl(''),
      lingotekProjectId: new FormControl(''),
      amazonTranslateAccessKey: new FormControl(''),
      amazonTranslateSecretKey: new FormControl(''),
      amazonTranslateRegion: new FormControl(''),
      amazonTranslateEndpoint: new FormControl(''),
      smartlinguseridentifier: new FormControl(''),
      smartlingusersecret: new FormControl(''),
      projectId: new FormControl(''),

      deeplAuthKey: new FormControl(''),
      deeplTranslateEndpoint: new FormControl(''),
      googleAPIKey: new FormControl('')
    });
  }

  fetchCustomers() {
    this.spinner = true;
    this.customerService.getActiveCustomers(localStorage.getItem('loginUserId'))
      .subscribe(response => {
        this.spinner = false;
        this.customers = response.response.data;
      }, error => {
        this.spinner = false;
        if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
          return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
         } else {
          return this.snackBar.open(error.error.response.message, 'Close', this.config);
         }
      });
  }

  fetchCommunities(selectedCustomer: string) {
    if (selectedCustomer !== '') {
      this.spinner = true;
      this.mappingService.getCommunitybyCustomerId(+selectedCustomer)
        .subscribe(res => {
          this.spinner = false;
          this.communities = res.response.data;
          this.translationForm.controls['translation'].setValue(false);
          this.setDefault();
        }, error => {
          this.spinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
            return this.snackBar.open(error.error.response.message, 'Close', this.config);
           }
        });
    } else {
      this.translationForm.controls['translation'].setValue(false);
      this.communities = [];
      this.setDefault();
    }
  }

  populateAutoTransProvider() {

  }

  submit() {
    if (this.isValidationSuccess) {
      const translationObject = this.translationForm.value;
      const communityId: number = +translationObject.community;
      const dplSelectedLangs = this.mappedlanguages;
      const valid = this.validateFields(translationObject) !== false;
      if (valid) {
        const payLoad = this.prepareObject(
          communityId,
          this.communityName,
          this.customerName,
          translationObject,
          dplSelectedLangs,
          this.smartlingProjects
        );
        this.spinner = true;
        this.showSaveButton = false;
        this.communityService.update(payLoad, true).subscribe(
          (res) => {
            this.reset();
            this.spinner = false;
            console.log("Response" + res);
            this.modal.populateModal(res);
            this.showSaveButton = true;
            this.isValidationSuccess = true;
          },
          (error) => {
            this.spinner = false;
            this.isValidationSuccess = true;
            this.showSaveButton = true;
            if (
              error.status === 401 &&
              error.error.response.status === "error" &&
              error.error.response.message ===
                "UnAuthorized Access Token Expired"
            ) {
              return this.snackBar.open(
                Constants.SESSION_EXPIRED,
                "Close",
                this.config
              );
            } else {
              return this.snackBar.open(
                error.error.response.message,
                "Close",
                this.config
              );
            }

          }
        );
      }
    }
  }

  validateTranslationKeys() {
    this.showSaveButton=false;
    this.isValidationSuccess = false;
    let message = "";
    const translationObject = this.translationForm.value;
    const communityId: number = +translationObject.community;
    const dplSelectedLangs = this.mappedlanguages;
    const valid = this.validateFields(translationObject) !== false;
    if (valid) {
      const payLoad = this.prepareObject(
        communityId,
        this.communityName,
        this.customerName,
        translationObject,
        dplSelectedLangs,
        this.smartlingProjects
      );
      this.spinner = true;
      this.communityService.validateTranslationKeys(payLoad).subscribe(
        (res: any) => {
          this.spinner = false;
          if (res.response.code === 200 && (res.response.data.status === 'success')) {
            message += res.response.data.message;
            this.modalComponent.populateModal({
              response: { message: message },
            });
            this.isValidationSuccess = true;
            this.showSaveButton = true;
            this.translationForm.disable();
            this.smartlingDataDisable=true;
          } else {
            message =Constants.INVALID_TRANSLATION_DETAILS;
            this.modalComponent.populateModal({
              response: { message: message },
            });
            this.isValidationSuccess = false;
            this.showSaveButton = false;
          }
        },
        (error) => {
          console.log("Error object:", error);
          this.spinner = false;
          this.isValidationSuccess = false;
          if (
            error.status === 401 &&
            error.error.response.status === "error" &&
            error.error.response.message === "UnAuthorized Access Token Expired"
          ) {
            this.snackBar.open(Constants.SESSION_EXPIRED, "Close", this.config);
          } else {
            this.snackBar.open(
              error.error.response.message,
              "Close",
              this.config
            );
          }
          this.showSaveButton = false;
        }
      );
    }
  }


  validateFields(obj: any) {
    if (obj.translation && (obj.translationProvider === '' || obj.translationLanguageCode === '')) {
      this.modal.populateModal({response: {message: 'Please select translation provider or translation language code'}});
      return false;
    }
    if (obj.translationProvider === 'google') {
      if(obj.googleTranslationAuthType === 'oauth2'){
        if (obj.translateAccessToken === '' || obj.translateRefreshToken === ''
          || obj.translateClientId === '' || obj.translateClientSecret === '' || obj.googleProjectId === '') {
          this.modal.populateModal({response: {message: 'Please enter accessdetails for remainging fields to save'}});
        return false;
        }
      } else if(obj.googleTranslationAuthType === 'apikey'){
        if (obj.googleAPIKey == '') {
          this.modal.populateModal({response: {message: 'Please enter accessdetails for remainging fields to save'}});
        return false;
        }
      }
    } else if (obj.translationProvider === 'lingotek') {
      if (obj.lingotekTranslateAccesskey === '' || obj.lingotekCallbackUserName === '' || obj.lingotekCallbackPassword === '') {
        this.modal.populateModal({response: {message: 'Please enter lingotek access details to save'}});
        return false;
      }
    } else if (obj.translationProvider === 'bing') {
      if (obj.microsoftTranslateSubscriptionKey === '') {
        this.modal.populateModal({response: {message: 'Please enter microsoftTranslateSubscriptionKey to save'}});
        return false;
      }  else if (obj.translationProvider === 'amazon_translator') {
          if (obj.amazonTranslateAccessKey === '' || obj.amazonTranslateSecretKey === '' || obj.amazonTranslateRegion === '' || obj.amazonTranslateEndpoint === '') {
                this.modal.populateModal({response:{message: 'Please enter Amazon Access Details for Translation'}});
                return false;
        }
      }
    } else if(obj.translationProvider === 'systan') {
      if (obj.translateAccessToken === '' || obj.translateClientId === '' || obj.translateClientSecret === '') {
        this.modal.populateModal({response: {message: 'Please enter accessdetails for remainging fields to save'}});
        return false;
      }
    }else if (obj.translationProvider === 'deepl') {
      if (obj.deeplAuthKey === '' || obj.deeplTranslateEndpoint === '') {
        this.modal.populateModal({response:{message: 'Please enter DeepL Access Details for Translation'}});
        return false;
      }
    }else if (obj.translationProvider === 'smartling') {
     let filteredlist = this.smartlingProjects.filter(d => (d.projectId ==='' || d.sourceLocaleCode==='' || d.smartlinguseridentifier === '' || d.smartlingusersecret === ''||d.interactionStyle === '')).length;
     if (filteredlist > 0) {
      this.modal.populateModal({response:{message: 'Please enter all the required details'}});
        return false;
     }
     let interactionStyles = this.smartlingProjects.map(project => project.interactionStyle);
     let uniqueStyles = new Set(interactionStyles);

     if (interactionStyles.length !== uniqueStyles.size) {
       this.modal.populateModal({ response: { message: 'Please select a unique interaction style for each Smartling project.' } });
       return false;
     }
  }
}

  /**
   * preparing pay load as per the provider
   * @param communityId
   * @param translationObj
   */
  private prepareObject(communityId: number,communityName: string,customerName: string, translationObj: any, dplSelectedLangs: any, smartlingProjects: any[]): any {
    const provider = translationObj.translationProvider;
    const payLoad: any = {
      'communityId': communityId,
      'communityName': communityName,
      'customerName': customerName,
      'translationLanguageCode': (translationObj.translation) ? translationObj.translationLanguageCode: '',
      'googleTranslationAuthType': (translationObj.translation) ? translationObj.googleTranslationAuthType: '',
      'translation': translationObj.translation,
      'autoDetectLanguageForLingotek': (translationObj.autoDetectLanguageForLingotek !== null && translationObj.autoDetectLanguageForLingotek !== false)? true: false,
      'translationProvider': translationObj.translationProvider
    };
    payLoad.deeplGlossaryMapping  =  dplSelectedLangs;
    payLoad.translateAccessToken = translationObj.translateAccessToken;
    payLoad.translateRefreshToken = translationObj.translateRefreshToken;
    payLoad.translateClientId = translationObj.translateClientId;
    payLoad.glossariesId = translationObj.glossariesId;
    payLoad.glossariesLocation = translationObj.glossariesLocation;
    payLoad.translateClientSecret = translationObj.translateClientSecret;
    payLoad.googleProjectId = translationObj.googleProjectId;
    payLoad.lingotekTranslateAccesskey = translationObj.lingotekTranslateAccesskey;
    payLoad.microsoftTranslateSubscriptionKey = translationObj.microsoftTranslateSubscriptionKey;
    payLoad.lingotekCallbackUserName = translationObj.lingotekCallbackUserName;
    payLoad.lingotekCallbackPassword = translationObj.lingotekCallbackPassword;
    payLoad.lingotekProjectId = '';
    payLoad.amazonTranslateAccessKey = translationObj.amazonTranslateAccessKey;
    payLoad.amazonTranslateSecretKey = translationObj.amazonTranslateSecretKey;
    payLoad.amazonTranslateRegion = translationObj.amazonTranslateRegion;
    payLoad.amazonTranslateEndpoint = translationObj.amazonTranslateEndpoint;
    payLoad.smartlingProjects = smartlingProjects;
    payLoad.deeplAuthKey = translationObj.deeplAuthKey;
    payLoad.deeplTranslateEndpoint = translationObj.deeplTranslateEndpoint;
    payLoad.googleAPIKey = translationObj.googleAPIKey;
    return payLoad;
  }

  populateTransProvider() {
    const checked = this.translationForm.controls['translation'].value;
    if (checked) {
      const selectedCommunity = this.translationForm.controls['community'].value;
      if (selectedCommunity !== '') {
        this.checkTranslationStatus(selectedCommunity, true);
      }
      this.fetchTranslationprovider();
      this.fetchInteractionType();
      this.fetchTranslationAuthTypes();
      this.showSaveButton=false;
    } else {
      this.translationProviders = [];
      this.interationTypes = [];
      this.translationAuthTypes = [];
      this.languages = [];
      this.setDefault();
      this.showSaveButton=true;
      this.isValidationSuccess = true;
    }
  }

  fetchTranslationprovider() {
    this.translationProviders = JSON.parse(Constants.TRANSLATION_PROVIDER);
  }

  fetchInteractionType() {
    this.interationTypes = JSON.parse(Constants.INTERACTION_TYPE);
  }

  fetchTranslationAuthTypes() {
    this.translationAuthTypes = JSON.parse(Constants.TRANSLATION_AUTHTYPE);
  }

  fetchLanguages(provider: string) {
    if (provider !== '') {
      this.provider = provider;
      this.spinner = true;
      this.communityService.getSupportedLanguages(provider)
        .subscribe(res => {
          this.spinner = false;
          this.languages = res.response.data;
        }, error => {
          this.spinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
            return this.snackBar.open(error.error.response.message, 'Close', this.config);
           }
        });
    }
  }


  checkTranslationStatus(selectedCommunity, status) {

    if (selectedCommunity !== 0) {
      const community: Community = this.communities.filter((communityObj: Community) => communityObj.communityId === +selectedCommunity)[0];
      let communityWithAccessDetails: Community;
      this.spinner = true;
      this.communityService.getCommunitiesByCommunityId(community.communityId).subscribe(
        res => {
          this.spinner = false;
          communityWithAccessDetails = res.response.data;
          let index = this.communities.indexOf(community);
          this.communities[index] = communityWithAccessDetails;
          this.community = communityWithAccessDetails;
          if (community.translation) {
            this.isValidationSuccess = false;
            this.showSaveButton = false;
            this.smartlingProjects = this.community.smartLingProjects;
            this.communityName=this.community.communityName;
            this.customerName=this.community.customer.customerName;
            this.fetchTranslationprovider();
            this.fetchInteractionType();
            this.fetchTranslationAuthTypes();
            this.fetchLanguages(community.translationProvider);
            this.translationForm.patchValue(this.community);
            this.translationForm.patchValue(this.community.accessdetail);
          } else {
            this.smartlingProjects = this.community.smartLingProjects;
            this.translationForm.patchValue(this.community.accessdetail);
            this.setDefault();
          }
          this.translationForm.controls['community'].setValue(this.community.communityId);
          this.translationForm.controls['customer'].setValue(this.community.customerId);
         if (!status) {
          this.translationForm.controls['translation'].setValue(this.community.translation);
         }
         this.eventEnabled = false;
         this.mappedlanguages = [];
         if(communityWithAccessDetails.deeplGlossaryMapping.length > 0){
          this.eventEnabled = true;
          this.mappedlanguages = communityWithAccessDetails.deeplGlossaryMapping;
         }
        }, error => {
          this.spinner = false;
          if (error.status === 401 && error.error.response.status === 'error' && error.error.response.message === 'UnAuthorized Access Token Expired') {
            return this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
           } else {
            return this.snackBar.open(error.error.response.message, 'Close', this.config);
           }
        }
      )
    } else {
      this.translationForm.controls['translation'].setValue(false);
      this.setDefault();
    }
  }

  reset() {
    this.translationForm.reset();
    this.translationForm.enable();
    this.isValidationSuccess = false;
    this.showSaveButton = false;
    this.smartlingDataDisable=false;
    this.translationForm.controls['community'].setValue('');
    this.translationForm.controls['customer'].setValue('');
    this.setDefault();
    if (this.superAdmin) {
      this.communities = [];
      this.fetchCustomers();
    } else {
      this.fetchCommunities(this.customerObj.id);
    }
  }


  setDefault() {
    this.translationForm.controls['translationProvider'].setValue('');
    this.translationForm.controls['translationLanguageCode'].setValue('');
    this.translationForm.controls['googleTranslationAuthType'].setValue('');
    this.translationForm.controls['translateAccessToken'].setValue('');
    this.translationForm.controls['translateRefreshToken'].setValue('');
    this.translationForm.controls['translateClientId'].setValue('');
    this.translationForm.controls['translateClientSecret'].setValue('');
    this.translationForm.controls['glossariesId'].setValue('');
    this.translationForm.controls['glossariesLocation'].setValue('');
    this.translationForm.controls['googleProjectId'].setValue('');
    this.translationForm.controls['lingotekTranslateAccesskey'].setValue('');
    this.translationForm.controls['lingotekCallbackUserName'].setValue('');
    this.translationForm.controls['lingotekCallbackPassword'].setValue('');
    this.translationForm.controls['microsoftTranslateSubscriptionKey'].setValue('');
    this.translationForm.controls['amazonTranslateAccessKey'].setValue('');
    this.translationForm.controls['amazonTranslateSecretKey'].setValue('');
    this.translationForm.controls['amazonTranslateRegion'].setValue('');
    this.translationForm.controls['amazonTranslateEndpoint'].setValue('');
    //this.translationForm.controls['smartlinguseridentifier'].setValue('');
    //this.translationForm.controls['smartlingusersecret'].setValue('');
    //this.translationForm.controls['smartlingprojectid'].setValue('');


    this.translationForm.controls['deeplAuthKey'].setValue('');
    this.translationForm.controls['deeplTranslateEndpoint'].setValue('');
    this.translationForm.controls['googleAPIKey'].setValue('');
  }

  /**
   * added for dynamic rendering of form fileds
   * based on the provider
   * @param field
   */
  public canShow(field: string): boolean {
    if (this.provider !== '') {
      const data = Jsonfile[this.provider];
      if (data && data[field] === undefined || data[field] === true) {
        this.translationForm.addControl(field, new FormControl('', [Validators.required]));
        this.translationForm.controls[field].setValue(this.community.accessdetail[field]);
        return true;
      }
      this.translationForm.removeControl(field);
      return false;
    }
  }
  populateLanguages = (isChecked) => {
    if (isChecked) {
      this.mappedlanguages= this.mappedlanguages.length ? this.mappedlanguages : [];
      if (!this.mappedlanguages.length) {
        let targetObject = {"sourceLanguage":"", "targetLanguage":"", "glossaryId":""};
        this.mappedlanguages.push(targetObject);
      }
    }
  }
  add(){
    let mappedlang = this.mappedlanguages;
    if(mappedlang[mappedlang.length-1].sourceLanguage !="" && mappedlang[mappedlang.length-1].targetLanguage !=""&& mappedlang[mappedlang.length-1].glossaryId !=""){
      let targetObject = {"sourceLanguage":"", "targetLanguage":"", "glossaryId":""};
      this.mappedlanguages.push(targetObject);
    }
  }

  sourceLangUpdate = (event,i) => {
    this.mappedlanguages[i].sourceLanguage = event.target.value;
  }
  targetLangUpdate = (event,i) => {
    this.mappedlanguages[i].targetLanguage = event.target.value;
    console.log(this.mappedlanguages)
  }
  public confirm(index) {
    this.mappedlanguages.splice(index, 1);
  }
  glosseriesId= (event,i) => {
    this.mappedlanguages[i].glossaryId = event.target.value;
    console.log(this.mappedlanguages);
  }

  public addSmartlingProject() {
    let filteredlist = this.smartlingProjects.filter(d => d.projectId=== '' || d.sourceLocaleCode === '' || d.smartlinguseridentifier === '' || d.smartlingusersecret === ''|| d.interactionStyle === '');
    if (filteredlist.length > 0) {
     this.modal.populateModal({response:{message: 'Please enter all the required details'}});
       return false;
    } else {
      this.smartlingProjects.push({
        'projectId': '',
        'sourceLocaleCode': '',
        'smartlinguseridentifier': '',
        'smartlingusersecret': '',
        'interactionStyle':'',
        'communityId': this.translationForm.controls['community'].value
      });
    }
  }
  delete(id: number, index: number) {
    this.isDelete = true;
    this.smartlingId=id;
    this.index=index;
    this.modalComponent.populateModal({response: {message: Constants.DELETE_CONFIRMATION_MESSAGE}});
  }
  confirmRemoveProject(confirmMsg: any) {
    const x = document.getElementById('myModal');
    if (confirmMsg) {
      x.style.display = 'none';
      this.spinner = true;
      if(this.smartlingId === undefined){
        this.spinner = false;
        const res="Smartling project has been successfully deleted";
        this.smartlingProjects.splice(this.index, 1);
        this.modalComponent.populateModal({response: {message: res}});
      }
      else{
        const translationObject = this.translationForm.value;
        const communityId: number = +translationObject.community;
        const communityName: any = +translationObject.community;
        const dplSelectedLangs = this.mappedlanguages;
        const payLoad = this.prepareObject(
          communityId,
          this.communityName,
          this.customerName,
          translationObject,
          dplSelectedLangs,
          this.smartlingProjects
        );
        this.communityService.deleteSmartlingProjectById(this.smartlingId, payLoad).subscribe(
          res => {
            this.spinner = false;
            const msg = res && res.response ? res.response.message : null;
            if (msg === 'Smartling project has been successfully deleted') {
              this.smartlingProjects.splice(this.index, 1);
              this.modalComponent.populateModal({ response: { message: msg } });
            } else {
              this.snackBar.open('Error occurred while deleting the SmartlingProject', 'Close', this.config);
            }
          },
          error => {
            this.spinner = false;
            let errorMessage = 'Error occurred while deleting the SmartlingProject';
            if (error && error.error && error.error.res && error.error.res.response && error.error.res.response.message) {
              errorMessage = error.error.res.response.message;
            }
            if (error.status === 401 && errorMessage === 'UnAuthorized Access Token Expired') {
              this.snackBar.open(Constants.SESSION_EXPIRED, 'Close', this.config);
            } else {
              this.snackBar.open(errorMessage, 'Close', this.config);
            }
          }
        );


    }
    } else {
      x.style.display = 'none';
    }

    this.isDelete = false;
  }


}
